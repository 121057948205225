/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

form
	div.DivForm
		h3
			margin-bottom: $SPACE_LITTLE
			font-size: $FONT_LABEL_H3
		fieldset
			legend
				font-size: $FONT_LABEL_LEGEND
				margin-top: $SPACE_LITTLE
				margin-bottom: $SPACE_LITTLE
			label
				font-size: $FONT_LABEL_GENERAL
				margin-bottom: $SPACE_NARROW
				margin-top: $SPACE_SMALL
				display: block
			label.LabelPrefix
				margin-right: $SPACE_LITTLE
			label.LabelSuffix
				margin-left: $SPACE_LITTLE
			label.LabelModel
				font-weight: bold
				font-size: $FONT_LABEL_PROPERTY
				margin-bottom: $SPACE_MEDIUM
			label.LabelSatisfactionSurvey
				font-size: 1.75em
				font-weight: 700
			label.LabelSatisfactionSurveyQuestion
				font-weight: 600
			span.SpanActiveStatus, i.IActiveStatus
				margin-right: $SPACE_MEDIUM
				vertical-align: middle
				display: inline-block
				padding-top: $SPACE_TINY
				padding-left: $SPACE_LITTLE
				padding-right: $SPACE_LITTLE
				padding-bottom: $SPACE_TINY
				font-size: $FONT_SPAN_TICKETSTATUS
				margin: 0px
				+setBorderRadius($RADIUS_TICKET_STATUS)
		span.SpanCustomerPhone
			display: flex !important
			select
				width: $WIDTH_INPUT_PHONE
				margin-right: $SPACE_LITTLE
	label.LabelAttachment
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		+setBorderRadius(50%)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-size: $FONT_LABEL_H2
		h3
			font-size: $FONT_LABEL_H3
		span
			font-size: $FONT_LABEL_SUBTITLE
	div.DivForm
		h2, h3
			display: block
			margin-bottom: $SPACE_LITTLE
		h2
			margin-top: $SPACE_LITTLE
			font-size: $FONT_LABEL_H2
		h3
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H3
		h3:first-of-type
			margin-top: 0px
		h4
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H4
		h4:first-of-type
			margin-top: 0px
		label
			font-size: $FONT_LABEL_GENERAL
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_SMALL
			display: block
			i
				font-size: 15px
				font-weight: 550
			i.textNormal
				font-style: normal !important
		label.mdc-floating-label
			line-height: auto
			margin-top: $SPACE_TINY
			width: 100%
			margin-bottom: 0px
		.mdc-text-field--filled .mdc-floating-label--float-above
			width: $WIDTH_LABEL_FLOATING_ABOVE
		div.DivHeaderHeadline
			div.DivSubHeaderHeadline
				label
					margin-top: 0
		span, p, textarea#spanIncidentNote
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			display: block
		span, p
			word-break: break-all
		span.LabelStatus, span.LabelStatusTotal
			font-weight: 600
			font-size: $FONT_LABEL_STATUSDASHBOARD
		span.SpanRupiah
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			word-break: normal
		.mat-mdc-form-field, .legend-wrap
			span
				font-size: inherit
				margin-bottom: 0px
				margin-top: 0px
				display: inline-block
				.legend-label-text
					word-break: break-word
					white-space: normal
		.legend-wrap
			.horizontal-legend
				li
					display: block
		.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label
			top: $SPACE_SMALL
		.mat-mdc-form-field-subscript-wrapper
			display: none
		dl
			dt, dd
				font-size: $FONT_LABEL_HEADERINFO
		div.DivHeaderHeadline
			div.DivHeaderStatus
				span
					font-size: $FONT_LABEL_SUBTITLE
		fieldset
			div.DivHeaderInfo
				dl
					dt, dd
						font-size: $FONT_DATALIST_HEADERINFO
		h4.HeadingItems
			margin-left: 17px

div.DivProfileInfo
	div.DivProfileInfoDetails
		a.Underline
			text-decoration: underline
	div.DivProfilePhoto
		span.SpanChatProfileNameInitial
			margin: 0px
			font-family: $URL_FAMILYSECONDARY_PRIMARY
			color: white
			font-size: $FONT_SPAN_PROFILENAMEINITIAL
			display: block
			text-align: center

div.DivContainerForUpload
	div.DivContainerForAttachment
		p
			margin-top: 0px
			font-size: 16px
			margin-bottom: 0px
		label
			margin-bottom: 0px
			font-size: 14px
			margin-top: 5px
			font-style: italic

// GENERAL - END


// NAVIGATION - START

nav
	figure
		text-align: center
		span#spanProfileNameInitial
			font-size: $FONT_LABEL_PROFILENAMEINITIAL
			display: block
		figcaption
			span#spanProfileName, span#spanProfilePosition
				display: block
			span#spanProfileName
				font-size: $FONT_LABEL_PROFILENAME
			span#spanProfilePosition
				font-size: $FONT_LABEL_PROFILEPOSITION
		ul, a.SignOut
				font-weight: 600
	ul
		list-style-type: none
		li
			margin-top: $SPACE_LARGE
			margin-bottom: $SPACE_LARGE
	ul.ListNavigation
		li.ListItemNavigation
			font-size: $FONT_LIST_NAVIGATIONITEM
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				li.ListItemSubNavigation
					font-size: $FONT_LIST_NAVIGATIONSUBITEM

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	header#headerSignIn
		h1, span
			display: block
		h1
			font-size: $FONT_LABEL_H1
			letter-spacing: $SPACE_LITTLE
			margin-bottom: -$SPACE_LITTLE
		span
			font-size: $FONT_LABEL_SPAN
	form
		div.DivNotRegistered
			text-align: center
			font-weight: 500

// SIGN IN - END


// DASHBOARD - START

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		margin-top: $SPACE_MEDIUM
		margin-bottom: $SPACE_MEDIUM
		text-align: center
		span.SpanHighlight
			padding-top: $SPACE_LITTLE
			padding-bottom: $SPACE_LITTLE
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			margin: $SPACE_LITTLE
			min-width: $WIDTH_LABEL_HIGHLIGHT
			display: block
			+setBorderRadius($RADIUS_LABEL_HIGHLIGHT)
	div.DivDashboardEmeterai
		fieldset
			p
				font-weight: 500
				letter-spacing: 5px
				font-size: $FONT_DASHBOARD_EMATERAI

form div.DivForm fieldset, div.DivForm
	ul
		list-style: none
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		div.DivListChart
			span
				font-size: $FONT_DASHBOARD_SPAN
			ul
				padding: $SPACE_LITTLE
				+setBorderRadius($RADIUS_DASHBOARD_LIST)
				li
					list-style: none
					margin-top: $SPACE_NARROW
					margin-bottom: $SPACE_LITTLE
					span
						font-size: $FONT_DASHBOARD_SPANLIST
						display: inline
					span:first-of-type
						+setBorderRadius($RADIUS_DASHBOARD_LIST)
						padding: $SPACE_TINY $SPACE_LITTLE
						margin: $SPACE_TINY

// DASHBOARD - END


// TABLE - START

div.DivTableContainer
	div.DivTableControl
		span.SpanTablePage
			margin-left: $SPACE_MEDIUM
			margin-right: $SPACE_MEDIUM
			font-size: $FONT_SPAN_TABLEPAGE
		label
			margin-right: $SPACE_MEDIUM
			white-space: nowrap
			font-size: $FONT_LABEL_GENERAL
	ul.ListTableThumbnails
		figure
			figcaption
				font-size: $FONT_LABEL_GENERAL
	ul.ListTableTicket, ul.ListTableConversation
		div.DivTicketItem, div.DivConversationGeneral
			span.SpanTicketTitle
				font-size: $FONT_LIST_TABLETITLE
			div.DivTicketSubItem, div.DivConversationItem
				div.DivTicketHeader, div.DivConversationHeader
					span
						display: block
					span.SpanTicketNumber, span.SpanConversationDate
						font-size: $FONT_DATA_TICKETLABEL
					span.SpanTicketTitle, span.SpanConversationTitle
						font-size: $FONT_LIST_TABLETITLE
						display: block
					span.SpanConversationDate
						text-align: right
					span.SpanTicketNumber
						display: inline-flex
						p
							margin-left: 5px
							border-left: solid 3px white
							padding-left: 5px
				div.DivTicketTail, div.DivConversationStatus
					span
						margin-right: $SPACE_MEDIUM
						vertical-align: middle
						display: inline-block
						padding-top: $SPACE_TINY
						padding-left: $SPACE_LITTLE
						padding-right: $SPACE_LITTLE
						padding-bottom: $SPACE_TINY
						font-size: $FONT_SPAN_TICKETSTATUS
						+setBorderRadius($RADIUS_TICKET_STATUS)
					span:first-of-type
						margin-left: 0px
						margin-bottom: $SPACE_LITTLE
					span:last-of-type
						margin-right: 0px
		li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						span.SpanConversationDate
							text-align: left
		dl
			dt, dd
				margin-left: 0px
				margin-right: 0px
				margin-bottom: $SPACE_THIN
				margin-top: $SPACE_THIN
				font-size: $FONT_DATA_TICKETLABEL
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
	table.TableListDetails
		font-size: 0.9em
		font-weight: 600
	table.TableListDetailsAttachment
		input[type=file]
			display: none
		label.LabelAttachment, label.LabelAttachmentDisable
			display: block
			width: $SIZE_ICON_LDPI
			height: $SIZE_ICON_LDPI
			+setBorderRadius(50%)
		label.LabelAttachment:first-of-type, label.LabelAttachmentDisable:first-of-type
			margin-left: auto
		label.LabelAttachment:last-of-type, label.LabelAttachmentDisable:last-of-type
			margin-right: auto
		div.DivMultipleLabelAttachment
			display: flex
			margin-top: -5px
			margin-bottom: -5px
			label.LabelAttachment
				margin-left: $SPACE_TINY
				margin-right: $SPACE_TINY

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	div.DivForm
		div.DivListControl
			h3
				margin: 0px
			div.DivListControlButton
				label
					margin-left: $SPACE_LITTLE
					margin-right: $SPACE_LITTLE
					font-size: $FONT_SPAN_TABLEPAGE
				label, input[type=button]
					display: inline-block

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationHeader
				h3
					margin: 0
				div.DivConversationInfo
					div.DivConversationInfoDetails
						span
							margin: 0px
							text-align: right
			div.DivConversationContent
				div.DivAttachmentContainer
					label
						margin: 0px
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					margin: 0px
					font-family: $URL_FAMILYSECONDARY_PRIMARY
					color: white
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
					width: $SPACE_BIG
					height: $SPACE_ENORMOUS
					padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
					text-align: center

div#divBodyClaimProposal
	div#divMainClaimProposalDetailApproval
		div.DivDynamicContainer
			margin-right: $SPACE_SMALL
			form
				margin-right: 0px
			div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
				label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
					a.NotVerified, a.Verified
						font-size: $FONT_LABEL_SPAN

// DYNAMIC CONTAINER - END


// LOADING - START

div#divCurtainLoading
	text-align: center
	div#divLoadingMessage
		span#spanLoadingPercentage
			margin-bottom: $SPACE_HUGE
			font-size: $FONT_SPAN_LOADINGPERCENTAGE
		span
			font-size: $FONT_LABEL_GENERAL

// LOADING - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	span
		font-size: $FONT_LABEL_GENERAL
		font-weight: bold
	input#buttonNotificationClose
		font-size: $FONT_SIZE_CLOSE
	p#paragraphNotificationInstallIOS
		display: none
div#divNotificationInstallList
	span
		display: block
		margin-bottom: $SPACE_TINY

// NOTIFICATION - END


// APPROVAL - START

div.DivForm
	div.DivCard, div.DivCardFinish
		span#spanTitle
			font-size: 12pt
		span#spanName
			margin-top: -7.5px
			font-size: 16pt

// APPROVAL - END


// RETURN AMOUNT START

div.DivForm
	div.DivFormReturnAmount
		fieldset
			h3:first-of-type
				margin-top: -15px
			h3
				margin-top: -15px
				font-size: 12pt
				align-items: center


// RETURN AMOUNT END


// INFO WINDOWS GOOGLE MAPS PLATFORM START

div.DivDynamicContainer
	map-info-window
		a
			font-weight: bold
			margin-bottom: $SPACE_LITTLE
		p
			margin-top: $SPACE_LITTLE

div.DivFormBranch
	span
		font-weight: bold
		text-transform: capitalize

// INFO WINDOWS GOOGLE MAPS PLATFORM END


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		div.DivFormBranch
			p
				position: absolute
				font-size: $SPACE_MEDIUM
				right: 0
				margin-top: 18px
				margin-right: 155px

// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //


// Span Sub Text Reporter Information Submission Report Detail START //

form
	div.DivForm
		span#spanSubText
			margin-top: 0
			font-size: $FONT_LABEL_SUBTITLE
			font-family: $URL_FAMILYPRIMARY_PRIMARY

// Span Sub Text Reporter Information Submission Report Detail END //


// History Progress - START

div#divHistoryProgressStepper
	tr.TableRowProgress
		p
			font-size: $FONT_CLAIMPROPOSAL_HISTORYPROGRESS
			word-break: break-word
		p.ParagraphNote
			font-size: $FONT_CLAIMPROPOSAL_HISTORYPROGRESS_NOTE

// History Progress - END


// .FORM SURVEY SATISFACTION START
div.DivFormContainerForSatisfactionSurvey
	div.DivForm
		h2
			font-size: 20px
			margin-top: 20px
		div.DivContainerSurvey
			div.DivWrapperSurvey
				div.DivContainerHorizontalRadioButton
					div.DivRadioButtonContainer
						flex-direction: column
						align-items: center
						label
							font-weight: 600
							font-size: 18px
				div.DivContainerRatingDetail
					font-size: 24px
					font-weight: 700
// .FORM SURVEY SATISFACTION END



// .TRACKING CLAIM START
div.DivTrackingStepperContainer
	ul
		li.LiStepperHorizontal, li.LiStepperHorizontalForChildNoLine, li.LiStepperHorizontalForParentNoLine, li.LiStepperHorizontalForChildNoLineDisable
			div.DivContainerContentStepper
				h4
					position: relative
					top: 0px
					left: 5px
				span
					margin-top: 35px
			div.DivTitleStepper
				div.DivSubTitleStepper, div.DivSubTitleStepperForSurvey, div.DivSubTitleStepperForDetailInfo
					label, label.LabelCheck, label.LabelInformation
						font-size: 18px
						position: relative
					label::before
						position: absolute
						left: -25px
						top: 0px
						border-radius: 50%
						content: ''
						width: 20px
						height: 20px
					label.LabelCheck::before
						position: absolute
						left: -25px
						top: 0px
						border-radius: 50%
						content: ''
						width: 20px
						height: 20px
						background-size: 20px
						background-repeat: no-repeat
				div.DivContainerSteperContent
					label.labelTime::before
						content: ''
						position: absolute
						left: 140px
						width: 3px
						height: 25px
					label.labelTime::after
						content: ''
						position: absolute
						left: 221px
						width: 3px
						height: 25px
					div.DivContainerForImage
						div.DivWrapperImg
							div.DivImg
								label
									font-size: 18px
									width: 180px
								label:nth-child(2)
									margin-bottom: 0px
								label:nth-child(3)
									margin-top: 0px
					div.DivContainerForUpload
						div.DivContainerForAttachment
							p
								font-size: 14px
								margin-top: 0px
								font-size: 16px
								margin-bottom: 0px
							label
								font-size: 12px
								margin-bottom: 0px
								font-size: 14px
								margin-top: 5px
			div.DivTitleStepperContent
				label
					margin-left: 5px
			div.DivContainerDateTimePicker
				mat-form-field
					span
						margin-top: 0px
div.DivFormContainerForSatisfactionSurvey
	div.DivForm
		h2
			font-size: 20px
			margin-top: 20px
		div.DivContainerSurvey
			div.DivWrapperSurvey
				div.DivContainerHorizontalRadioButton
					div.DivRadioButtonContainer
						label
							font-weight: 600
							font-size: 18px
		fieldset
			textarea
				height: 115px !important
				padding: 20px

div.DivContainerSteperContent
	div.DivSubTitleStepperForSurvey
		div.DivContainerForRating
			div.DivWrapperForRating
				span
					text-align: center
					height: 20px
					margin: 0px
					padding: 0px
					margin-right: 40px
					color: #787878
					font-weight: 300
					font-family: "helveticaneuecyr_roman"
				span::after
					content: ' '
					width: 55px
					height: 55px
					background-size: 20px
					border: none
					background-repeat: no-repeat
					position: absolute
					margin-left: 10px
				label.LabelForRating
					margin: 0px
					padding: 0px
				label::before
					display: none

// .TRACKING CLAIM END


// .SALVAGE - START

div.DivInputPrefix
	input.inputTextPrefix
		margin-right: 10px
		flex:1
	input.inputTextContent
		flex:5

label.LabelSalvageAttachment
	width: $SIZE_ICON_MDPI
	height: $SIZE_ICON_MDPI
	+setBorderRadius(50%)

div.DivHistoryApproval
	span
		margin-top: 0px !important
	div.DivApprovalDetail
		label
			margin-bottom: 0px

div.DivJuklakAttachmentContainer
	label
		margin-top: 16px !important
		margin-bottom: 16px !important
	figure.FigureHorizontalCaption
		figcaption.FigCaptionVertical
			span
				margin: 0px 5px 0px 5px !important
			label
				font-style: italic
				margin: 0px 5px 0px 5px !important

div#divMoreDocumentContainer
	input
		margin: 0px
	span#spanAddText
		display: flex
		align-items: center
		margin-left: 20px

// .SALVAGE - END


// E-MATERAI - START

div.DivButtonDashboardEmaterai
	p
		width: 100%
		font-size: 1.25em

div.DivSingleForm
	div.DivFormVerticalContainer
		label.LabelEmateraiSurveyStatus
			a
				font-size: $FONT_SIZE_LINK_EMATERAI
			span.SpanEmateraiSurveyStatus
				margin-top: 0px
				margin-bottom: 0px
				font-size: $FONT_SIZE_SPAN_EMATERAI
				margin-bottom: $SPACE_SMALL

// E-MATERAI - END


// GCG ONLINE - START

div.DivMainGCGOnlineOverview
	div.DivSingleForm
		form
			div.DivForm
				div.DivCheckBoxContainer > label
					font-size: $FONT_DATALIST_OVERVIEW

div.DivMainGCGOnlineDetail
	div.DivSingleForm
		form
			div.DivForm
				span.SpanStatusHistoryApproval
					font-size: $FONT_SIZE_SPAN_STATUS_APPROVAL
					margin-bottom: $SPACE_ZERO

div.DivMainGCGOnlineOverview, div.DivMainGCGOnlineDetail
	div.DivSingleForm
		form
			div.DivForm
				fieldset, fieldset.FieldsetContainerList
					ol.OrderListAlphabet > li
						font-size: $FONT_DATALIST_OVERVIEW
					ol.OrderListAlphabet
						line-height: 1.7em
						list-style-type: upper-alpha
						li
							text-align: justify
							padding-left: $SPACE_LITTLE
							ol.OrderListNumber
								padding-left: $SPACE_MEDIUM
								li
									padding-left: $SPACE_LITTLE
									&::marker
										font-weight: 100
							&::marker
								font-weight: 600
					ol.OrderListNumber
						li
							ol.OrderListAlphabet
								line-height: inherit
								padding-left: $SPACE_MEDIUM
								list-style-type: lower-alpha
								li
									&::marker
										font-weight: 100
				fieldset.FieldsetContainerOverview
					font-size: $FONT_DATALIST_OVERVIEW
					p
						word-break: break-word
						span.SpanHighlight
							display: inline
							font-size: inherit
							word-break: break-word
					ol.OrderListNumber
						line-height: 1.3em
						padding-left: $SPACE_MEDIUM

// GCG ONLINE - END


/* SURVEY ONLINE - START */

div#divNotificationOnlineSurvey
	div.DivNotificationOnlineSurveyCard
		div.DivNotificationOnlineSurveyContent
			label
				font-size: 28px
				font-weight: 700
			p
				font-size: 19px

/* SURVEY ONLINE - END */

/* KLAIM DASHBOARD - START */

div#divMainDashboard
	form
		div.DivTableDashboardContainer
			h3
				margin-bottom: $SPACE_LITTLE
				font-size: $FONT_LABEL_H3
			p
				text-decoration: underline
	div.DivContainerCountDashboard
		div.DivClaimCount
			span
				margin-top: 20px
				text-align: center
				font-size: 4em
				color: #000
				font-weight: 300
			div.DivHorizontalCountHeader
				h3
					margin: 0px 0px 10px 0px
				h3.headerCount
					text-align: end
					color: #000
					width: 100%
	div.DivTableDashboardContainer
		div.DivTableHorizontalContainer
			div.DivHorizontalTextContainer
				h3
					margin: 0px
					text-align: start
					width: 80%
				p
					margin: 0px
					text-align: end
					width: 20%
					cursor: pointer

/* KLAIM DASHBOARD - END */

// BRINSPRO - START

div.DivBodyGeneral
	div.DivMainGeneral
		div.DivFormContainerProjectDetail
			h3
				color: white
				font-size: 1.2em
			div.DivFormProjectDetail
				div.DivFormProgress
					h3
						color: #F26F21
						font-size: 1.5em
		div.DivForm
			div.DivFormHorizontalPercentage
				span
					font-size: 1em
					width: 25%
				div.DivContainerPercentage
					span.SpanWhite
						color: white
						width: 100%
					span.SpanBlack
						color: black
						width: 100%
			div.DivSpanForDivision
				span
					margin-top: 0px
					margin-bottom: 0px
		div.DivFormUploadDetail
			h3
				color: white
			div.DivFormContainerAttachmentFile
				div.DivFormContainerUploadDetail
					div.DivContainerForAttachment
						p, label
							color: white
							font-size: 16px
div.DivBodyGeneral
	div.DivMainGeneral
		div.DivFormContainerDashboardBrinsproDate
			h3
				color: white
				margin-bottom: 10px
				font-size: 20px
			.mat-mdc-form-field-infix
				width: 325px
		div.DivFormContainerDashboardBrinspro
			div.DivFormFirstColumn
				div.DivFormContainerDashboardChart
					div.DivContainerChart
						div.DivChartBase
							div.DivChart
								span
									color: white
									font-size: 1em
									margin-bottom: 0px
									margin-top: 4px
			div.DivFormSecondColumn
				div.DivProjectBudgetContent
					h4
						color: black
				div.DivForm
					div.DivFormContainerLegend
						div.DivFormLegendLabel
							label
								margin-left: 10px
								margin-top: 5px
								margin-bottom: 7px
								font-size: 13px
			h1
				font-weight: 700
				font-size: 100px
				text-align: center
				margin-top: 45px
			label
				text-align: center
				font-size: 1em
			label.LabelProject
				margin-top: 50px
				margin-bottom: 20px
			h4
				text-align: center
				font-size: 30px
				font-weight: 700

		div.DivFormContainerDashboardBrinsproLast
			div.DivFormSecondColumnLast
				div.DivProjectContent
					h1
						margin-top: 0px
					label
						margin-top: -10px

// BRINSPRO - END

/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SCREEN WIDTH 1450px - START

@media screen and (min-width: 1450px)
	div.DivTableContainer
		ul.ListTableConversation
			li
				div.DivConversationGeneral
					div.DivConversationHeader
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: initial !important

// SCREEN WIDTH 1450px - END


// SCREEN WIDTH 1424px - START

@media screen and (max-width: 1424px)
	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: none

// SCREEN WIDTH 1424px - END


// SCREEN WIDTH 1330px - START

@media screen and (max-width: 1330px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			flex-direction: column
			div.DivContainer
				margin-top: $SPACE_SMALL
				margin-right: 0px
				margin-left: 0px
				margin-bottom: $SPACE_SMALL
			div.DivFormVerticalContainer
				flex-direction: row
				justify-content: flex-start
	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			div.DivAttachmentContainer
				display: flex
				justify-content: flex-end
	div#divMainDashboard
		div.DivDualForm
			div.DivDualForm2Column
				div.DivFormJustify
					margin-top: 15px
				div.DivForm
					div.DivFormCSatContainer
						margin-top: 0px

// SCREEN WIDTH 1330px - END


// SCREEN WIDTH 1250px - START

@media screen and (max-width: 1250px)
	div#divBodyHome main
		padding: $SPACE_LARGE

	div.DivVerifierGeneral, div.DivCenterGeneral
		margin: $SPACE_LARGE

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_LARGE

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_LARGE

	input[type=button]#buttonNavigation
		left: $SPACE_LARGE

	div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
		margin-top: 60px

	div.DivMainAsideSearch
		form
			margin-right: $SPACE_LITTLE
			width: $WIDTH_LAYOUT_SIGNIN_COMPACT

	div.DivTableContainer
		margin-left: $SPACE_LITTLE

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 140px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 140px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div#divBodyApprovalClaimProposal
		div.DivMainAsideSearch
			form#formApprovalClaimProposal
				width: $WIDTH_LAYOUT_APPROVAL_MEDIUM
		div#divMainApprovalClaimProposal
			div.DivDynamicContainer
				margin-left : 0px

// SCREEN WIDTH 1250px - END


// SCREEN WIDTH 1140px - START

@media screen and (max-width: 1140px)
	div#divBodyHome main
		padding: $SPACE_SMALL

	div.DivVerifierGeneral, div.CenterGeneral
		margin: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_SMALL

	input[type=button]#buttonNavigation
		left: $SPACE_SMALL

	div.DivMainAsideSearch
		flex-direction: column
		form
			width: 100%
		div.DivDynamicContainer
			margin-left: 0px
			div.divInformationMenuClaimProposal
				margin-top: 0px

	div.DivForm
		padding-left: $SPACE_SMALL
		padding-right: $SPACE_SMALL

	div.DivTableContainer
		margin-left: 0px

	div#divBodyApprovalClaimProposal
		div.DivMainAsideSearch
			flex-direction: row
			justify-content: space-evenly
			form#formApprovalClaimProposal
				width: $WIDTH_LAYOUT_APPROVAL_TINY
		div.DivSubDynamicContainer
			padding-left: 0px
			margin-top: $SPACE_MEDIUM
			margin-left: $SPACE_MEDIUM
			div.DivListControl
				margin-left: $SPACE_LARGE
	div#divMainDashboard
		div.DivContainerCountDashboard, div.DivContainerDashboard
			flex-wrap: wrap
		div.DivContainerDashboard
			div.DivFormMaps
				margin-left: 0px

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 100px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 100px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: block

	form
		div.DivForm
			div.DivTrackingStepperContainer
				ul
					li.LiStepperHorizontal
						div.DivContainerContentStepper
							div.DivTitleStepper
								ul
									li
										div.DivContainerSteperContent
											div.DivContainerForImage
												flex-wrap: wrap

	div.DivMainAsideSearch
		div.DivMainTable
			margin-left: $SPACE_ZERO

	div#divBodyDashboard
		div.DivMainGeneral
			div.DivFormContainerDashboardBrinspro
				display: flex
				flex-direction: column
				div.DivFormFirstColumn
					width: 100%
					div.DivFormContainerProject
						div.DivFormProjectTotal
							div.DivProjectContent
								margin-top: 92px
								label.LabelProject
									margin-top: 100px
				div.DivFormSecondColumn
					width: 100%
			div.DivFormContainerDashboardBrinsproLast
				margin-top: 5px
				div.DivFormSecondColumnLast
					margin-top: 20px
		div.DivDualForm
			div.DivDualForm2Column
				div.DivFormJustify
					margin-top: 0px
				div.DivForm
					div.DivFormCSatContainer
						margin-top: 40px


// SCREEN WIDTH 1140px - END


// SCREEN WIDTH 950px - START

@media screen and (max-width: 950px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivFormVerticalContainer, div.DivFormHorizontalContainer
				flex-direction: column
				align-content: center
				align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormVerticalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px
				div.DivImageCapture
					video
						width: 90%
						height: 90vh
		div.DivFormHorizontalContainer#divDashboardTicketContainer
			div.DivForm2Column
				width: unset
				min-width: 75%
				margin-left: $SPACE_ZERO
				margin-right: $SPACE_ZERO
			div.DivFormHorizontalContainer
				div.DivForm2Column
					width: 100%

	div#divBodyCustomerInformation
		div.DivSubDynamicContainer
			padding-left: 0px
			margin-top: $SPACE_SMALL
			div.DivListControl
				margin-left: $SPACE_LARGE

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationStatus
					flex-direction: column
					flex-shrink: 1
				div.DivConversationHeader
					flex-direction: column
					align-items: flex-end
					div.DivConversationProfile
						display: none
			table.TableListDetails
				tbody
					tr
						td
							display: table-cell
						td:nth-child(2), td:nth-child(3)
							width: auto
						td:nth-child(3)
							display: none

	div#divBodyApprovalClaimProposal
		div.DivSubDynamicContainer
			margin-top: $SPACE_MEDIUM
			// div.DivFormHorizontalContainer
			// 	flex-direction: column
			// 	div.DivForm2Column
			// 		margin-left: 0px
			// 		margin-right: 0px
			// 		width: 100%
			div.DivListControl
				margin-left: $SPACE_LARGE

	div#divBodyDashboard
		div.DivMainGeneral
			div.DivFormContainerDashboardBrinspro
				display: flex
				flex-direction: column
				div.DivFormFirstColumn
					width: 100%
					div.DivFormContainerProject
						div.DivFormProjectTotal
							div.DivProjectContent
								margin-top: 0px
								label.LabelProject
									margin-top: 55px
				div.DivFormSecondColumn
					width: 100%
			div.DivFormContainerDashboardBrinsproLast
				margin-top: 5px
				div.DivFormSecondColumnLast
					margin-top: 20px
		div.DivDualForm
			div.DivDualForm2Column
				div.DivFormJustify
					margin-top: 60px
					flex-direction: row
				div.DivForm
					div.DivFormCSatContainer
						margin-top: 0px

// SCREEN WIDTH 950px - END


// SCREEN WIDTH 800px - START

@media screen and (max-width: 800px)
	div.DivVerifierGeneral, div.DivCenterGeneral
		header.HeaderGeneral
			padding-top: 60px

	div.DivVerifierGeneral, div.DivCenterGeneral
		img#imageLogoHeader
			right: auto
			left: 0px

	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			div.DivAttachmentContainer
				display: flex
				justify-content: flex-end
		div.DivDashboardEmeterai
			flex-direction: column
			text-align: center

	div.DivTableContainer
		table
			thead
				tr
					th
						word-break: break-all
			tbody, tfoot
				tr
					td
						word-break: break-all
		ul.ListTableThumbnails
			justify-content: space-around
		ul.ListTableConversation
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						justify-content: flex-end

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 80px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 80px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div#divBodyApprovalClaimProposal
		div.DivMainAsideSearch
			flex-direction: column
			justify-content: space-between
			form#formApprovalClaimProposal
				width: 100%
		div.DivSubDynamicContainer
			padding-left: 0px
			margin-left: 0px
			margin-top: $SPACE_MEDIUM
			div.DivListControl
				margin-left: $SPACE_LARGE
			div.DivFormHorizontalContainer
				flex-direction: row

	div#divMainClaimProposalDetailApproval
		div.DivListControl
			div.DivNavigationControlButton
				display: contents

	// form
	// 	div.DivForm
	// 		div.DivTrackingStepperContainer
	// 			ul
	// 				li.LiStepperHorizontal
	// 					div.DivContainerContentStepper
	// 						div.DivTitleStepper
	// 							ul
	// 								li
	// 									div.DivContainerSteperContent
	// 										div.DivSubTitleStepperForSurvey
	// 											display: flex
	// 											flex-wrap: wrap

	div#divBodyDashboard
		div#divMainDashboard
			div.DivSingleForm
					form
						div.DivButtonContainer
							input[type=button]
								width: 100%

	div.DivForm
		div.DivFormHorizontalContainer
			div.DivForm2Column
				div.DivForm3ColumnOwnRetention
					display: contents
					justify-content: space-around

	div#divBodyDashboard
		div.DivMainGeneral
			div.DivFormContainerProjectDetail
				div.DivFormProjectDetail
					display: flex
					flex-direction: column
					div.DivTableProjectDetail
						width: 100%
					div.DivFormProgress
						width: 100%
			div.DivFormContainerDashboardBrinspro
				display: flex
				flex-direction: column
				div.DivFormFirstColumn
					width: 100%
					div.DivFormContainerProject
						div.DivFormProjectTotal
							div.DivProjectContent
								label.LabelProject
									margin-top: 37px
				div.DivFormSecondColumn
					width: 100%
			div.DivFormContainerDashboardBrinsproLast
				margin-top: 5px
				div.DivFormSecondColumnLast
					margin-top: 20px
		div.DivDualForm
			flex-direction: column
			div.DivDualForm2Column
				width: auto
				div.DivFormJustify
					margin-top: 0px
				div.DivForm
					div.DivFormCSatContainer
						margin-top: 0px


// SCREEN WIDTH 800px - END


// SCREEN WIDTH 660px - START

@media screen and (max-width: 660px)
	form.FormPerformance
		ngx-charts-pie-chart
			ngx-charts-chart
				.ngx-charts-outer
					justify-content: center

	div#divBodyDashboard
			div.DivMainGeneral
				div.DivFormContainerDashboardBrinspro
					div.DivFormContainerProject
						display: flex
						flex-direction: column
						div.DivFormProject
							width: 100%

// SCREEN WIDTH 660px - END


// SCREEN WIDTH 640px - START

@media screen and (min-width: 641px)
	div#divNotificationInstall
		width: 640px
		height: auto
		left: auto
		position: fixed
		right: $SPACE_LITTLE
		bottom: $SPACE_LITTLE
		+setBorderRadius($RADIUS_INPUT_GENERAL)

@media screen and (max-width: 640px)
	body
		font-size: 95%

	div#divBodyHome img#imageLogoHeader.DisplayNone
		display: none

	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivContainer
				div.DivFormHorizontalContainer
					flex-direction: column
					align-content: center
					align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px
				width: 100%
			div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
				div.DivImageCapture
					width: 100%
		div.DivFormReviewedOn
			flex-direction: row
		div.DivFormHorizontalContainer#divDashboardTicketContainer
			div.DivForm2Column
				min-width: 80%

	div.DivForm, form div.DivForm fieldset
		.mat-form-field
			width: 100%

	div.DivDynamicContainer
		div.DivConversationHeader
			div.DivConversationProfile
				display: none

	div.DivTableContainer, div.DivTableSalvageContainer
		ul.ListTableTicket
			img
				display: none
			div.DivTicketItem
				div.DivTicketSubItem:last-of-type
					display: flex
					flex-direction: column
				div.DivTicketTail
					display: flex
					flex-shrink: 1
			li
				padding-left: $SPACE_SMALL
				padding-right: $SPACE_SMALL
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				justify-content: center
				width: 100%
		div.DivTableControl
			flex-direction: column
			div.DivTableSubControl
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
		table
			margin-top: $SPACE_SMALL
			margin-bottom: $SPACE_SMALL
			thead
				tr
					th
						padding: $SPACE_THIN
						display: block
					th:first-of-type
						padding-left: 0px
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
						+setBorderRadiusBottom(0px)
					th:last-of-type
						padding-right: 0px
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				tr:first-of-type
					th
						padding-top: $SPACE_TINY
				tr:last-of-type
					th
						padding-bottom: $SPACE_TINY
			tbody, tfoot
				tr
					td
						padding: $SPACE_THIN
						display: block
						+setBorderRadius(0px)
					td:first-of-type
						padding-top: $SPACE_TINY
						padding-left: 0px
					td:last-of-type
						padding-right: 0px
						padding-bottom: $SPACE_TINY
				tr:first-of-type
					td:first-of-type
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
					td:last-of-type
						+setBorderRadiusTop(0px)
				tr:last-of-type
					td:first-of-type
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom(0px)
					td:last-of-type
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				div.DivMultipleLabelAttachment
					margin: 0
			th, td
				text-align: center

	div#divMainClaimProposalDetailApproval
		div.DivListControl
			div.DivNavigationControlButton
				display: flex
				flex-wrap: wrap
				justify-content: center
				label
					width: 100px

	main.MainShow
		+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
		+setTransition(all, 0.5, ease-in)
	main.MainHide
		+setSlideTransform(-60, 180px, -180px, 0.6, 0.6, 0.6)
		+setTransition(all, 0.5, ease-out)

	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

	#divMainDashboard
		div.DivFormCardInside
			position: relative
			margin: $SPACE_MEDIUM
			width: 100%
			z-index: 1
			margin-left: 0px
			div.DivFormBranch
				margin-bottom: $SPACE_BIG
				div.DivBranchCardInformation
					img
						border-radius: 50%
						padding: $SPACE_TINY
				input[type=button].ButtonShare
					background-position-x: 170px
				p
					margin-right: 200px
		div.DivFormNoBorder
			google-map
				.map-container
					border-radius: $SPACE_SMALL

	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

	// START - HISTORY PROGRESS

	div#divHistoryProgressStepper
		table.TableStepHistory
			tr.TableRowProgress
				flex-direction: column
				border-left: solid 1px #000
				p
					font-size: 0.9em
				td.TableDataProgressDate, td.TableDataProgressDetail
					margin-left: 10px
				td.TableDataProgressDate
					border-right: 0
				td.TableDataProgressDetail
					p
						margin-top: 5px


	// END - HISTORY PROGRESS


	// SALVAGE START //
	figure.FigureHorizontalCaption
		display: block
		margin-left: 27px !important
		margin-right: 27px !important


	div.DivTableSalvageContainer
		table
			tbody
				tr
					td.TableDataAttachment
						padding-left: 0px
		table.TableSalvageAlignCenter
			text-align: center
			th:nth-child(1)
				height: 18px
			th
				padding: 0px 0px 0px 2px !important
				width: 100% !important
		table.TableListDetailsAttachment
			td
				width: 100% !important
				word-break: break-all

	// SALVAGE END //


	// E-MATERAI - START

	div.DivBodyEmaterai
		flex-direction: column-reverse

	div.DivButtonDashboardEmaterai
		flex-direction: column-reverse
		align-items: center
		text-align: center

	// E-MATERAI - END

	div.DivForm
		div.DivFormHorizontalContainer
			div.DivForm2Column
				div.DivForm3ColumnOwnRetention
					display: contents
					justify-content: space-around

	// BRINSPRO - START

	div#divBodyDashboard
		div.DivMainGeneral
			div.DivTableContainer
				table.TableWithInput
					td:nth-child(1)
						width: 100%
					td:nth-child(2)
						width: 100%

	// BRINSPRO - END


// SCREEN WIDTH 640px - END


// SCREEN WIDTH 490px - START

@media screen and (max-width: 490px)
	ul.ListTableConversation
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					flex-direction: column
					align-items: flex-start
					div.DivConversationHeader
						margin-top: $SPACE_LITTLE
						div.DivConversationSubHeader
							span
								text-align: left !important
							span.SpanConversationTitle
								max-width: initial !important


	div.DivForm
		div.DivFormHorizontalContainer
			div.DivForm2Column
				div.DivForm3ColumnOwnRetention
					display: contents
					justify-content: space-around

	div.DivForm
		div.DivFormHorizontalContainer
			div.DivForm2Column
				div.DivImageCapture
					div.DivDescriptionItems
						display: flex
						flex-direction: column
						margin-bottom: 30px

	div#divBodyDashboard
			div.DivMainGeneral
				div.DivFormContainerDashboardBrinsproDate
					.mat-mdc-form-field-infix
						width: 200px
				div.DivFormContainerDashboardBrinsproLast
					margin-top: 5px
					div.DivFormSecondColumnLast
						margin-top: 20px

// SCREEN WIDTH 490px - END
